<template>
  <div class="home">
    <el-row class="top">
      <el-col :lg="{span: 16, offset: 4}" :xs="{span: 22, offset: 1}">
        <el-row class="head">
          <el-col align="center" class="head-logo">
            <img class="head-logo-img" src="../assets/image/logo.png" alt="新奇站">
          </el-col>
        </el-row>
        <el-row class="body">
          <div class="body-top"></div>
          <div class="body-content">
            <div class="body-content-title">收據</div>
            <el-row class="body-content-english-title">
              <el-col :lg="{span:10}" :xs="{span:8}"><hr class="body-content-english-title-hr"></el-col>
              <el-col :lg="{span:4}" :xs="{span:8}">RECEIPT</el-col>
              <el-col :lg="{span:10}" :xs="{span:8}"><hr class="body-content-english-title-hr"></el-col>
            </el-row>
            <el-row class="body-content-order">
              <el-row>
                <el-col :lg="{span:11,offset:1}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    店鋪/Store
                  </div>
                  <div class="body-content-order-value">
                    <a style="color: black" id="accountBrandLink" v-bind:href="saleBill.accountBrand.link" target="_blank">{{ saleBill.accountBrand.name }}</a>
                  </div>
                </el-col>
                <el-col :lg="{span:11}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    訂單號碼/Order ID
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.code }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="{span:11,offset:1}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    客友/Customer
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.receiptName }}
                  </div>
                </el-col>
                <el-col :lg="{span:11}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    取貨日期/Pick-up Date
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.deliveryTime | formatDate }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="{span:11,offset:1}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    電話/Tel
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.receiptPhone }}
                  </div>
                </el-col>
                <el-col :lg="{span:11}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    自取點/Pick-up Address
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.receiptAddress }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="{span:11,offset:1}" :xs="{span:22,offset:1}">
                  <div class="body-content-order-title">
                    訂單日期/Order Date
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.openInvoiceTime | formatDate }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :offset="1" :span="22">
                  <div class="body-content-order-title">
                    備註/Remark
                  </div>
                  <div class="body-content-order-value">
                    {{ saleBill.note }}
                  </div>
                </el-col>
              </el-row>
            </el-row>
            <el-row class="body-content-detail">
              <el-col :offset="1" :span="22" >
                <div class="body-content-detail-title">
                  購物清單/Shopping List<img class="body-content-detail-title-icon" src="../assets/image/subscript.png" alt="新奇站">
                </div>
              </el-col>
              <el-col :offset="1" :span="22">
                <div class="body-content-detail-table">
                  <el-row class="body-content-detail-table-head">
                    <el-col :xs="9" :lg="9">
                      <div class="body-content-detail-table-head-value">產品</div>
                      <div class="body-content-detail-table-head-english-value">Product</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-head-value">保養期(月)</div>
                      <div class="body-content-detail-table-head-english-value">Warranty</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-head-value">數量</div>
                      <div class="body-content-detail-table-head-english-value">Quantity</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-head-value">價格</div>
                      <div class="body-content-detail-table-head-english-value">Price({{ saleBill.currency }})</div>
                    </el-col>
                  </el-row>
                  <el-row class="body-content-detail-table-body" v-for="(itemDetail,index) in itemDetails" :key="index">
                    <el-col :xs="9" :lg="9">
                      <div class="body-content-detail-table-body-product">
                        {{ itemDetail.sku.item.name }}<br><span class="body-content-detail-table-body-product-standard">{{ itemDetail.sku.standard }}</span>
                      </div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value" v-if="itemDetail.sku.warranty">{{ itemDetail.sku.warranty }}</div>
                      <div class="body-content-detail-table-body-value" v-else>/</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">{{ itemDetail.count }}</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">${{ itemDetail.unitPriceUse * itemDetail.count / 100 }}</div>
                    </el-col>
                  </el-row>
                  <el-row class="body-content-detail-table-body" v-for="(projectDetail,index) in saleBill.projectDetails" :key="index+100">
                    <el-col :xs="9" :lg="9">
                      <div class="body-content-detail-table-body-product">
                        {{ projectDetail.projectName }}
                      </div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">/</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">{{ projectDetail.laborTime }}</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">${{ projectDetail.unitPriceUse * projectDetail.laborTime /100 }}</div>
                    </el-col>
                  </el-row>
                  <el-row class="body-content-detail-table-body" v-for="(serviceBill,index) in saleBill.carServiceBillBOList" :key="index+100">
                    <el-col :xs="9" :lg="9">
                      <div class="body-content-detail-table-body-product">
                        新奇站年度會員
                      </div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">/</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">1</div>
                    </el-col>
                    <el-col :xs="5" :lg="5">
                      <div class="body-content-detail-table-body-value">${{ serviceBill.ordersAmountUse/100 }}</div>
                    </el-col>
                  </el-row>
                  <el-row class="body-content-detail-table-body-tr">
                    <el-col :span="15" :offset="8">
                      <div class="body-content-detail-table-body-subtotal">合計/Subtotal ${{ saleBill.sellPriceUse + saleBill.itemDiscountPriceUse + saleBill.servicePriceUse }}</div>
                      <div class="body-content-detail-table-body-discounts">折扣/Discounts ${{ -saleBill.itemDiscountPriceUse }}</div>
                    </el-col>
                  </el-row>
                  <el-row class="body-content-detail-table-foot">
                    <el-col :span="15" :offset="8">
                      <div class="body-content-detail-table-foot-total">總額/TOTAL ${{ saleBill.sellPriceUse + saleBill.servicePriceUse }}</div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
        <el-row class="foot">
          <img class="foot-banner-img" src="../assets/image/smallBanaer.png" alt="新奇有趣有品位">
        </el-row>
      </el-col>
    </el-row>
    <el-row class="bottom">
      <el-col :lg="{span: 16, offset: 4}" :xs="{span: 22, offset: 1}">
        <el-row class="bottom-description">
          <el-col :span="16" class="bottom-description-info">
            此單據僅適用於在新奇站及其授權經銷商之購物證明，保養服務由新奇站售後保養中心提供
          </el-col>
          <el-col :span="8" class="bottom-description-service">
            <a @click="centerDialogVisible = true">服務條款</a>
          </el-col>
        </el-row>
        <el-row class="bottom-copyright">
          <el-col :span="16" class="bottom-copyright-info">
            Copyright 2023 Newki<br>All Right reserved
          </el-col>
          <el-col :span="8" class="bottom-copyright-logo">
            <img class="bottom-copyright-logo-img" src="../assets/image/thirdLogo.png">
            <br><a style="color: white;font-size: 0.9rem;" href="https://www.newkiland.com/" target="_blank">www.newki.com</a>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 彈窗內容 -->
    <el-dialog :visible.sync="centerDialogVisible" width="80%" center>
      <span>
        <h2 style="text-align:center">*服務條款</h2>
        <p>
          &nbsp;&nbsp;感謝您對新奇站（Newkiland）的支持，我們竭力為您提供優質的商品及滿意的購物體驗，同時為確保您享受有效的服務保障，特此設立此服務條例。
        </p>
        <h3>一、本店提供電子收據，用於交易之買賣雙方合法利益保障</h3>
        <h3>二、商品如出現質量問題，提供店鋪電子收據即可享受售後服務</h3>
        <h3>三、售後服務內容</h3>
        <h4>1、退貨換貨服務 *退換貨商品需符合：</h4>
        <li>① 非特價商品或贈品</li>
        <li>② 包裝未損壞且齊全</li>
        <li>③ 非曾維修過之商品</li>
        <li>④ 非人為損壞之商品</li>
        <h4>2、保養維修服務</h4>
        <div>於承諾保養期內出現質量問題的商品，可享受保養期內服務</div>
        <h4>3、商品保價服務</h4>
        <div>在購買7日內出現降價之商品，可享受保價服務</div>
        <h3>四、售後處理流程</h3>
        <li>1、與本店之客服人員說明售後情況</li>
        <li>2、如客服人員判斷售後情況屬實將會由售後專員特別為您服務</li>
        <li>3、當售後專員判斷售後情況屬實，將會為您提供售後方案</li>
        <h3>五、關於退貨退款</h3>
        <li>1、需由消費者將商品退回到指定待交收門店</li>
        <li>2、退款前需先將貨物退回確認方可申請退款</li>
        <li>3、退款由交收門店處理或者轉賬之方式進行</li>
        <li>4、確認退貨退款方案退款時間約1-3個工作日</li>
        <h3>一般條款</h3>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;本政策受香港特別行政區法律約束。我們雙方均不可撤銷且無條件遵守特區法庭的非專屬司法管轄。我們中任何一方若未能對本政策內任何條款要求強制執行並不構成對該條款的放棄,亦不會影稍後要求強制執行該條款之權利。
        </p>
        <div>本次服務的最終解釋權歸我司官方擁有。</div>
        <div style="margin-top: 20px" align="right">全朋友有限公司</div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">閱 讀 完 畢</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'SaleBillReceipt',
  data () {
    return {
      centerDialogVisible: false,
      saleBill: {
        projectDetails: [],
        accountBrand: {
          name: '',
          link: ''
        },
        client: {}
      },
      itemDetails: [],
      accountBrandLogoLink: ''
    }
  },
  created () {
    var billId = this.getParam()
    this.get(billId)
    console.log(window.innerWidth)
  },
  computed: {},
  mounted () {

  },
  filters: {
    formatDate (time) {
      var date = new Date(time)
      return dateFormat(date)
    }
  },
  methods: {
    get (billId) {
      var that = this
      // 发送get请求
      // axios.get('http://127.0.0.1:8088/_common/jsonResult.html', {
      axios.get('http://47.52.61.12/api/_common/jsonResult.html', {
        params: {
          billId: billId,
          action_method: 'CarSaleBillAction.getSaleBillReceipt'
        }
      }, { emulateJSON: true })
        .then(function (result) {
          if (result.data.result) {
            var info = result.data.data
            that.saleBill = info
            that.saleBill.projectDetails = info.projectDetails
            that.saleBill.client = info.client
            that.saleBill.accountBrand = info.accountBrand
            that.saleBill.sellPriceUse = info.sellPriceUse / 100
            that.saleBill.itemDiscountPriceUse = info.itemDiscountPriceUse / 100
            if (info.carServiceBillBOList) {
              that.saleBill.servicePriceUse = info.carServiceBillBOList[0].ordersAmountUse / 100
            } else {
              that.saleBill.servicePriceUse = 0
            }
            that.itemDetails = info.itemDetails
            that.accountBrandLogoLink = require('@/assets/img/' + info.accountBrand.id + '.png')
          } else {
            that.$notify.error({
              title: '错误',
              message: result.data.msg
            })
          }
        }, function () {
          that.$notify.error({
            title: '错误',
            message: '获取数据失败！'
          })
        })
    },
    getParam () {
      /* 用途: 接收地址栏参数 */
      // 获取当前页面的url
      var urlInfo = window.location.href
      // [urlInfo.indexOf("?")]参数字符串开始的位置  [urlInfo.length]url的长度    得到参数值
      var billId = urlInfo.substr(urlInfo.indexOf('?') + 1, urlInfo.length)
      return billId
    }
  }
}

function dateFormat (date) {
  let ret = ''
  let fmt = 'YYYY-mm-dd'
  date = new Date(date)
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return fmt
}

</script>

<style scoped lang="scss">
  .home {
    width: 100%;
  }
  .top {
    width: 100%;
  }
  .bottom {
    background: #04ADEA;
    width: 100%;
    min-height: 15vh;
    color: white;
  }
  .bottom-description {
    margin-top: 3vh;
  }
  .bottom-description-info {
    font-size: 1rem;
    text-align: left;
  }
  .bottom-description-service {
    font-size: 1rem;
    text-align: right;
  }
  .bottom-copyright {
    margin-top: 8vh;
    margin-bottom: 5vh;
  }
  .bottom-copyright-info {
    font-size: 1rem;
    text-align: left;
    color: #64CBF5;
  }
  .bottom-copyright-logo {
    font-size: 1rem;
    text-align: right;
  }
  .bottom-copyright-logo-img {
    margin-left: 0.5vw;
    color: #EEEDF0;
    text-align: right;
    width: 8vw;
  }
  .head {
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .head-logo {
    width: 100%;
    text-align:center
  }
  .head-logo-img {
    width: 12rem;
  }
  .body {
    width: 100%;
    margin-top: 1vh;
    box-shadow: 4px 4px 1px #EFECF0;
  }
  .body-top {
    border-radius: 5px;
    background: #A9CD36;
    width: 100%;
    height: 1.5vh;
  }
  .body-content {
    margin-left: 0.2vw;
    margin-right: 0.2vw;
    padding-left: 2vw;
    padding-right: 2vw;
    background: #F9F9FB;
    min-height: 70vh;
  }
  .body-content-title {
    padding-top: 2vh;
    text-align: center;
    font-size: 2rem;
    font-weight: bolder;
  }
  .body-content-english-title {
    font-size: 1.5rem;
    margin-top: 0.8vh;
  }
  .body-content-english-title-hr {
    height:1px;border:none;border-top:3px dashed;
  }
  .body-content-order-title {
    margin-top: 3vh;
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
  }
  .body-content-order-value {
    margin-top: 0.5vh;
    font-size: 1.2rem;
    text-align: left;
  }
  .body-content-detail {
    width: 100%;
  }
  .body-content-detail-title {
    margin-top: 5vh;
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
  }
  .body-content-detail-title-icon {
    margin-left: 0.4vw;
    width: 12px;
  }
  .body-content-detail-table {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .body-content-detail-table-head {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    background: #EEEDF0;
  }
  .body-content-detail-table-body {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-bottom: 1px dashed;
  }
  .body-content-detail-table-head-value {
    font-weight: bolder;
    font-size: 1rem;
  }
  .body-content-detail-table-head-english-value {
    font-size: 0.5rem;
  }
  .body-content-detail-table-body-product {
    font-size: 1rem;
    font-weight: bolder;
    text-align: left;
    margin-left: 3vw;
  }
  .body-content-detail-table-body-product-standard {
    text-align: left;
    color: #C9C8CB;
  }
  .body-content-detail-table-body-value {
    font-size: 1rem;
  }
  .body-content-detail-table-body-tr {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
  .body-content-detail-table-body-subtotal {
    font-size: 1rem;
    font-weight: bolder;
    text-align: right;
  }
  .body-content-detail-table-body-discounts {
    font-size: 1rem;
    text-align: right;
  }
  .body-content-detail-table-foot {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    background: #EEEDF0;
  }
  .body-content-detail-table-foot-total {
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: right;
  }
  .foot {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .foot-banner-img {
    width: 20rem;
  }
</style>
